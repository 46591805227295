import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import AlertContext from "../contexts/Alerts/AlertContext";

import UserContext from '../contexts/Users/UserContext';
import Loading from './Loading';

function EditUser() {
    let navigate = useNavigate();
    let userContext = useContext(UserContext)
    let alertContext = useContext(AlertContext);

    const { setLoading, loading, removeLoading, user, authUser } = userContext;
    const { alert, showAlert } = alertContext;

    const [userData, setuserData] = useState({
        age: '',
        name: '',
        email: '',
    });

    useEffect(() => {
        if (!user)
            authUser()
        else
            setuserData({
                age: user.age,
                name: user.name,
                email: user.email,
            })
    }, [user]);

    const onChangeHandler = (e) => {
        setuserData({
            ...userData,
            [e.target.name]: e.target.value,
        });
    };

    const submitHandler = async (e) => {
        try {
            e.preventDefault();
            setLoading();
            let token = JSON.parse(localStorage.getItem("token"));
            await axios.put(
                "https://api-nodejs-todolist.herokuapp.com/user/me",
                userData,
                {
                    headers: { "Authorization": token.token },
                }
            );
            removeLoading();
            showAlert({
                type: "success",
                msg: 'Success',
            });
            authUser()
            setTimeout(() => {
                navigate("/user", { replace: true });
            }, 2000)
        } catch (error) {
            removeLoading();
            console.log(error.response.data);
        }
    };

    return (
        <div>
            <center>
                {loading && <Loading />}
                <div className="register">
                    <h2>Edit User Profile</h2>
                    {alert !== null && (
                        <div className={`alert-${alert.type}`}>
                            {alert.msg}
                        </div>

                    )}
                    <form onSubmit={submitHandler} style={{ marginTop: '50px' }}>
                        <label htmlFor="fname">First name:</label>
                        <br />
                        <input
                            type="text"
                            name="name"
                            value={userData.name}
                            onChange={onChangeHandler}
                        />
                        <br />
                        <label htmlFor="lname">Email:</label>
                        <br />
                        <input
                            type="email"
                            name="email"
                            value={userData.email}
                            onChange={onChangeHandler}
                        />
                        <br />
                        <label htmlFor="fname">Age :</label>
                        <br />
                        <input
                            type="number"
                            name="age"
                            value={userData.age}
                            onChange={onChangeHandler}
                        />
                        <br />
                        <br />
                        <input type="submit" value="Submit" />
                    </form>
                </div>
            </center>
        </div>
    )
}

export default EditUser
import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

import AlertContext from "../../contexts/Alerts/AlertContext";
import UserContext from '../../contexts/Users/UserContext';
import Loading from '../Loading';

function AddTask() {
  let navigate = useNavigate();
  let userContext = useContext(UserContext)
  let alertContext = useContext(AlertContext);

  const { setLoading, loading, removeLoading, authUser, user } = userContext;
  const { alert, showAlert } = alertContext;

  const [taskData, settaskData] = useState({
    description: "",
    completed: false
  });

  useEffect(() => {
    if (!user)
      authUser()
      // eslint-disable-next-line
  }, [user]);
  
  // eslint-disable-next-line

  const onChangeHandler = (e) => {
    settaskData({
      ...taskData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      setLoading();
      let token = JSON.parse(localStorage.getItem("token"));
      await axios.post(
        "https://api-nodejs-todolist.herokuapp.com/task",
        taskData,
        {
          headers: { "Authorization": token.token },
        }
      );
      removeLoading();
      showAlert({
        type: "success",
        msg: 'Task Added Successfully',
      });
      setTimeout(() => {
        navigate("/todos", { replace: true });
      }, 2000)
    } catch (error) {
      removeLoading();
      showAlert({
        type: "danger",
        msg: error.response.data,
      });
      console.log(error.response.data);
    }
  };

  return (
    <div>
      <center>
        {loading && <Loading />}
        <div className="register">
          <h2>Add Task</h2>
          {alert !== null && (
            <div className={`alert-${alert.type}`}>
              {alert.msg}
            </div>

          )}
          <form onSubmit={submitHandler} style={{ marginTop: '50px' }}>
            <label>Description: </label>
            <br />
            <input
              type="text"
              name="description"
              value={taskData.description}
              onChange={onChangeHandler}
            />
            <br /> <br />

            <label>Completed : </label>
            <input
              type="radio"
              name="completed"
              value={Boolean(true)}
              onChange={onChangeHandler}
            /> True
            <input
              type="radio"
              name="completed"
              value={Boolean(false)}
              onChange={onChangeHandler}
            /> False
            <br />
            <br />
            <input type="submit" value="Submit" />
          </form>
        </div>
      </center>
    </div>
  )
}

export default AddTask
import React from 'react'
import { Link } from "react-router-dom";
function Home() {
    return (
        <div className='home'>
            <center>
                <h1> Welcome to React Context API Demo</h1>
                <Link to="/"> <button> Home </button></Link>
                <Link to="/register"> <button> Register </button></Link>
                <Link to="/login"> <button> Login </button></Link>
            </center>
        </div>

    )
}

export default Home;
import { SET_LOADING, REMOVE_LOADING, USER_DATA, SET_IMG, REMOVE_USER } from "../types";

export default (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_LOADING:
      return {
        ...state,
        loading: false,
      };
    case USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case REMOVE_USER:
      return {
        ...state,
        user: {},
      };
    case SET_IMG:
      return {
        ...state,
        img: action.payload
      }
    default:
      return state;
  }
};

import { useReducer, useContext } from "react";
import TodoContext from "./TodoContext";
import UserContext from '../Users/UserContext';
import TodoReducer from "./TodoReducer";

import axios from "axios";

import { ALL_TASKS, SINGLE_TASK } from "../types.js";

function TodoState(props) {
    let userContext = useContext(UserContext)
    const initialState = {
        tasks: {},
        task: null
    };
    const [state, dispatch] = useReducer(TodoReducer, initialState);
    const { setLoading, removeLoading } = userContext;

    const getTasks = (data) => {
        dispatch({
            type: ALL_TASKS,
            payload: data
        })
    }

    const allTasks = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            setLoading()
            let { data } = await axios.get("https://api-nodejs-todolist.herokuapp.com/task", {
                headers: { "Authorization": token.token },
            });
            getTasks(data)
            removeLoading()

        } catch (err) {
            localStorage.removeItem("token");
            console.log(err.response.data)
        }
    }

    const getSingleTask = async (id) => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            let { data } = await axios.get(`https://api-nodejs-todolist.herokuapp.com/task/${id}`, {
                headers: { "Authorization": token.token },
            });
            dispatch({
                type: SINGLE_TASK,
                payload: data
            })
        } catch (err) {
            return err.response.data
        }
    }

    return (
        <TodoContext.Provider
            value={{
                task: state.task,
                tasks: state.tasks,
                allTasks,
                getTasks,
                getSingleTask
            }}
        >
            {props.children}
        </TodoContext.Provider>
    );
}

export default TodoState;

import { useReducer } from "react";
import userContext from "./UserContext";
import userReducer from "./UserReducer";

import axios from "axios";

import { SET_LOADING, REMOVE_LOADING, USER_DATA, SET_IMG, REMOVE_USER } from "../types.js";

function UserState(props) {
  const initialState = {
    loading: false,
    user: null,
    img: null
  };

  const [state, dispatch] = useReducer(userReducer, initialState);

  const setLoading = () => {
    dispatch({ type: SET_LOADING });
  };
  const removeLoading = () => {
    dispatch({ type: REMOVE_LOADING });
  };

  const removeUser = () => {
    dispatch({ type: REMOVE_USER })
  }


  const setUser = (data) => {
    dispatch({
      type: USER_DATA,
      payload: data
    })
  }

  const setImg = async (url) => {
    try {
      await axios.get(url)
      dispatch({
        type: SET_IMG,
        payload: url
      })
    } catch (err) {
      dispatch({
        type: SET_IMG,
        payload: null
      })
      console.log(err.response.data)
    }
  }

  const authUser = async () => {
    try {
      let token = JSON.parse(localStorage.getItem("token"));
      let { data } = await axios.get("https://api-nodejs-todolist.herokuapp.com/user/me", {
        headers: { "Authorization": token.token },
      });
      setUser(data)
      setImg(`https://api-nodejs-todolist.herokuapp.com/user/${data._id}/avatar`)
    } catch (err) {
      localStorage.removeItem("token");
      console.log(err.response.data)
    }
  };

  return (
    <userContext.Provider
      value={{
        user: state.user,
        img: state.img,
        loading: state.loading,
        setLoading,
        removeLoading,
        setUser,
        authUser,
        removeUser,
        setImg
      }}
    >
      {props.children}
    </userContext.Provider>
  );
}

export default UserState;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./utils/PrivateRoute";
import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import EditUser from "./components/EditUser";
import UploadImg from "./components/UploadImg";
import UserDashBoard from "./components/UserDashBoard";
import Todos from "./components/Todos/Todos";
import AddTask from "./components/Todos/AddTask";
import EditTask from "./components/Todos/EditTask";

import AlertState from "./contexts/Alerts/AlertState";
import UserState from "./contexts/Users/UserState";
import TodoState from "./contexts/Todos/TodoState";

function App() {
  return (
    <UserState>
      <AlertState>
        <TodoState>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />

              <Route element={<PrivateRoute />}>
                <Route path="/user" element={<UserDashBoard />} />
                <Route path="/user/edit" element={<EditUser />} />
                <Route path="/user/upload" element={<UploadImg />} />

                <Route path="/todos" element={<Todos />} />
                <Route path="/todos/add" element={<AddTask />} />
                <Route path="/todos/edit/:id" element={<EditTask />} />
              </Route>

            </Routes>
          </BrowserRouter>
        </TodoState>
      </AlertState>
    </UserState>
  );
}

export default App;

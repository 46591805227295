import React, { useEffect, useContext } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom'
import TodoContext from '../../contexts/Todos/TodoContext';
import UserContext from '../../contexts/Users/UserContext';
import Loading from '../Loading';

function Todos() {
    // let navigate = useNavigate();
    let userContext = useContext(UserContext)
    let todoContext = useContext(TodoContext);

    const { loading, authUser } = userContext;
    const { tasks, allTasks, getTasks } = todoContext;

    useEffect(() => {
        authUser()
        allTasks()
        // eslint-disable-next-line
    }, []);

    const showCompleted = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            let { data } = await axios.get("https://api-nodejs-todolist.herokuapp.com/task?completed=true", {
                headers: { "Authorization": token.token },
            });
            getTasks(data)
        } catch (err) {
            console.log(err.response.data)
        }
    }

    const deleteTask = async (e) => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            await axios.delete(
                `https://api-nodejs-todolist.herokuapp.com/task/${e.target.id}`, { headers: { "Authorization": token.token }, }
            );
            let data = tasks.data.filter(el => el._id !== e.target.id)
            getTasks({
                count: data.length,
                data: data
            })
        } catch (err) {
            console.log(err.response.data)
        }
    }

    return (
        <div>
            <Link to="/user"> Go Back</Link>
            <center>
                <Link to="/todos/add"><button>Add Task</button></Link>
                <button onClick={showCompleted}> Completed Tasks </button>
            </center>
            <h1>ALL TASKS</h1>
            {loading ? <Loading /> :
                tasks.data?.length && (<>
                    {
                        tasks.data.map((e, i) => <div style={{ backgroundColor: "rgb(226, 225, 225)", padding: "10px", marginTop: "10px" }} key={e.owner + i}>
                            <h2>{e.description}</h2>
                            <h5>Status : {e.completed ? "Completed" : "Pending"}</h5>
                            <Link to={`/todos/edit/${e._id}`}><button > Edit Task</button> </Link>
                            <button onClick={deleteTask} id={e._id}> Delete Task </button>
                        </div>)
                    }
                </>)
            }

        </div >
    )
}

export default Todos
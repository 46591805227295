import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom'

import AlertContext from "../../contexts/Alerts/AlertContext";
import TodoContext from '../../contexts/Todos/TodoContext';
import UserContext from '../../contexts/Users/UserContext';
import Loading from '../Loading';

function EditTask() {
  const { id } = useParams()
  let navigate = useNavigate();
  let userContext = useContext(UserContext)
  let alertContext = useContext(AlertContext);
  let todoContext = useContext(TodoContext);

  const { setLoading, loading, removeLoading, authUser } = userContext;
  const { alert, showAlert } = alertContext;
  const { task, getSingleTask } = todoContext;

  const [taskData, settaskData] = useState({
    description: '',
    completed: ''
  });

  useEffect(() => {
    authUser();
    if (!task)
      getSingleTask(id)
    //   authUser()
    else
      settaskData({
        description: task.data.description,
        completed: task.data.completed
      })
// eslint-disable-next-line
  }, [task]);

  const onChangeHandler = (e) => {
    settaskData({
      ...taskData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    try {
      e.preventDefault();

      setLoading();
      let token = JSON.parse(localStorage.getItem("token"));
      await axios.put(
        `https://api-nodejs-todolist.herokuapp.com/task/${id}`,
        taskData,
        {
          headers: { "Authorization": token.token },
        }
      );
      removeLoading();
      showAlert({
        type: "success",
        msg: 'Task Updated Successfully',
      });
      setTimeout(() => {
        navigate("/todos", { replace: true });
      }, 2000)
    } catch (error) {
      removeLoading();
      showAlert({
        type: "danger",
        msg: error.response.data,
      });
      console.log(error.response.data);
    }
  };

  return (
    <div>
      <center>
        {loading && <Loading />}
        <div className="register">
          <h2>Edit Task</h2>
          {alert !== null && (
            <div className={`alert-${alert.type}`}>
              {alert.msg}
            </div>

          )}
          <form onSubmit={submitHandler} style={{ marginTop: '50px' }}>
            <label>Description: </label>
            <br />
            <input
              type="text"
              name="description"
              value={taskData.description}
              onChange={onChangeHandler}
            />
            <br /> <br />

            <label>Completed : </label>
            <input
              type="radio"
              name="completed"
              value={Boolean(true)}
              onChange={onChangeHandler}
            // checked={taskData.completed ? true : false}
            /> True
            <input
              type="radio"
              name="completed"
              value={Boolean(false)}
              onChange={onChangeHandler}
            // checked={taskData.completed ? false : true}
            /> False
            <br />
            <br />
            <input type="submit" value="Submit" />
          </form>
        </div>
      </center>
    </div>
  )
}

export default EditTask
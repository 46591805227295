import { ALL_TASKS, SINGLE_TASK } from "../types.js";

export default (state, action) => {
    switch (action.type) {
        case ALL_TASKS:
            return {
                ...state,
                tasks: action.payload,
            };
        case SINGLE_TASK:
            return {
                ...state,
                task: action.payload,
            };
        default:
            return state;
    }
};

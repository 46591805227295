import React, { useEffect, useContext } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'

import AlertContext from "../contexts/Alerts/AlertContext";
import userContext from '../contexts/Users/UserContext';
import defaultImg from "../utils/user.jpeg";
function UserDashBoard() {
    let navigate = useNavigate();
    let UserContext = useContext(userContext)
    let alertContext = useContext(AlertContext);
    const { user, img, authUser, setUser, setImg } = UserContext
    const { alert } = alertContext;

    useEffect(() => {
        if (!user)
            authUser()
            // eslint-disable-next-line
    }, [user]);


    const logout = async () => {
        try {

            let token = JSON.parse(localStorage.getItem("token"));
            var config = {
                method: 'post',
                url: 'https://api-nodejs-todolist.herokuapp.com/user/logout',
                headers: {
                    'Authorization': token.token
                }
            };
            console.log(token.token);
            await axios(config);
            // console.log(res.data)
            localStorage.removeItem("token");
            // removeUser();
            setUser(null);
            navigate("/login", { replace: true });
        } catch (err) {
            // console.log(err);
            console.log(err.response.data)
            // navigate("/login", { replace: true });
        }
    }

    const deleteUser = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            const res = await axios.delete(`https://api-nodejs-todolist.herokuapp.com/user/me`,
                {
                    headers: { "Authorization": token.token },
                }
            );
            if (res.data.email) {
                setUser(null)
                localStorage.removeItem("token");
                navigate("/", { replace: true });
            }
        } catch (err) {
            console.log(err.response.data)
        }
    }


    const deleteImage = async () => {
        try {
            let token = JSON.parse(localStorage.getItem("token"));
            await axios.delete(`https://api-nodejs-todolist.herokuapp.com/user/me/avatar`,
                {
                    headers: { "Authorization": token.token },
                }
            );
            setImg(null)
        } catch (err) {
            console.log(err.response.data)
        }
    }


    return (
        <div>
            <div >
                <center>
                    <h1>UserDashBoard</h1>
                    <Link to="/todos"><button>Todos</button></Link>
                    <Link to="/user/edit"><button> Edit User Profile </button> </Link>
                    <Link to="/user/upload"><button> Upload Image </button> </Link>
                    <button onClick={deleteImage}> Delete Image </button>
                    <button onClick={deleteUser}> Delete User </button>
                    <button onClick={logout}> Logout </button>
                </center>
            </div>
            <center>
                {alert !== null && (
                    <div className={`alert-${alert.type}`}>
                        {alert.msg}
                    </div>

                )}
                <h2>User Profile</h2>
                {
                    (!img) ? (<img src={defaultImg} width="100px" alt="User Profile" />) : (<img src={img} width="100px" alt="User Profile" />)
                }
                <h4>{user && user.name}</h4>
                <p>{user && user.email}</p>
                <p>Age: {user && user.age}</p>
            </center>
        </div>

    )
}

export default UserDashBoard
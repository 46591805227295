import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import userContext from "../contexts/Users/UserContext";
import AlertContext from "../contexts/Alerts/AlertContext";
import Loading from "./Loading";

function Register() {
    const usersContext = useContext(userContext);
    const alertContext = useContext(AlertContext);

    const { setLoading, loading, removeLoading } = usersContext;
    const { alert, showAlert } = alertContext;
    let navigate = useNavigate();

    const [userData, setuserData] = useState({
        name: "",
        email: "",
        password: "",
        age: "",
    });

    const onChangeHandler = (e) => {
        setuserData({
            ...userData,
            [e.target.name]: e.target.value,
        });
    };
    const submitHandler = async (e) => {
        try {
            e.preventDefault();
            setLoading();
            const { data } = await axios.post(
                "https://api-nodejs-todolist.herokuapp.com/user/register",
                userData
            );
            localStorage.setItem("token", JSON.stringify(data))
            removeLoading();
            navigate("/user", { replace: true });
        } catch (error) {
            let data = error.response.data
            removeLoading();
            showAlert({
                type: "danger",
                msg: data,
            });
            // console.log(error.response.data);
        }
    };

    return (
        <>
            <center>
                {loading && <Loading />}
                <div className="register">
                    <h2>User SignUp</h2>
                    {alert !== null && (
                        <div className={`alert-${alert.type}`}>
                            {alert.msg}
                        </div>

                    )}
                    <form onSubmit={submitHandler} style={{ marginTop: '50px' }}>
                        <label htmlFor="fname">First name:</label>
                        <br />
                        <input
                            type="text"
                            name="name"
                            value={userData.name}
                            onChange={onChangeHandler}
                        />
                        <br />
                        <label htmlFor="lname">Email:</label>
                        <br />
                        <input
                            type="email"
                            name="email"
                            value={userData.email}
                            onChange={onChangeHandler}
                        />
                        <br />
                        <label htmlFor="fname">Password:</label>
                        <br />
                        <input
                            type="password"
                            name="password"
                            value={userData.password}
                            onChange={onChangeHandler}
                        />
                        <br />
                        <label htmlFor="fname">Age :</label>
                        <br />
                        <input
                            type="number"
                            name="age"
                            value={userData.age}
                            onChange={onChangeHandler}
                        />
                        <br />
                        <br />
                        <input type="submit" value="Submit" />
                    </form>
                </div>
            </center>
        </>
    );
}

export default Register;

import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import AlertContext from "../contexts/Alerts/AlertContext";

import UserContext from '../contexts/Users/UserContext';
import Loading from './Loading';

function UploadImg() {
    let navigate = useNavigate();
    let userContext = useContext(UserContext)
    let alertContext = useContext(AlertContext);

    const { setLoading, loading, removeLoading, authUser, user, setImg } = userContext;
    const { alert, showAlert } = alertContext;

    const [avatar, setavatar] = useState({
        avatar: null
    });

    useEffect(() => {
        if (!user)
            authUser()
    }, [user]);

    const submitHandler = async (e) => {
        try {
            e.preventDefault();
            setLoading();
            let token = JSON.parse(localStorage.getItem("token"));
            await axios.post(`https://api-nodejs-todolist.herokuapp.com/user/me/avatar`,
                avatar,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "Authorization": token.token
                    }
                }
            );
            setImg(`https://api-nodejs-todolist.herokuapp.com/user/${user._id}/avatar`)
            removeLoading();
            showAlert({
                type: "success",
                msg: 'Success',
            });
            setTimeout(() => {
                navigate("/user", { replace: true });
            }, 2000)
        } catch (error) {
            removeLoading();
            showAlert({
                type: "danger",
                msg: error.response.data.error,
            });
            console.log(error.response.data);
        }
    };

    return (
        <div>
            <center>
                {loading && <Loading />}
                <div className="register">
                    <h2>Upload Image</h2>
                    {alert !== null && (
                        <div className={`alert-${alert.type}`}>
                            {alert.msg}
                        </div>

                    )}

                    <form onSubmit={submitHandler} style={{ marginTop: '50px' }}>
                        <input type="file" name="avatar" onChange={(e) => {
                            setavatar({
                                ...avatar,
                                [e.target.name]: e.target.files[0]
                            })
                            removeLoading();
                        }} />
                        <br />
                        <br />
                        <input type="submit" value="Submit" />
                    </form>
                </div>
            </center>
        </div>
    )
}

export default UploadImg
import { useReducer } from "react";
import AlertContext from "./AlertContext";
import AlertReducer from "./AlertReducer";
import { SET_ALERT, REMOVE_ALERT } from "../types.js";

function AlertState(props) {
    const initialState = null;

    const [state, dispatch] = useReducer(AlertReducer, initialState);

    const showAlert = (data) => {
        dispatch({
            type: SET_ALERT,
            payload: data
        })
        setTimeout(() => {
            dispatch({
                type: REMOVE_ALERT,
            })
        }, 3500);
    };

    return (
        <AlertContext.Provider value={{
            alert: state,
            showAlert
        }}>
            {props.children}
        </AlertContext.Provider>
    )


}

export default AlertState;
import loading from "../utils/loading.gif";

function Loading() {
  return (
    <>
      <center>
        <img src={loading} alt="Loading.." />
      </center>
    </>
  );
}

export default Loading;
